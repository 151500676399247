.updated-time {
  color: #5e5e5e;
  letter-spacing: 0.23px;
  line-height: 20px;
  margin-top: 8px;
}

.alerts-table,
.files-table {
  min-height: 200px;
  max-height: 40%;
  overflow-y: auto;
  &.empty {
    height: auto;
  }
}
