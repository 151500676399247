@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

html,
body {
  display: flex;
  flex-direction: column;
  height: 100%;
}
#root {
  min-height: 100%;
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: "Omnes", sans-serif;
  box-sizing: border-box;
}

h1 {
  height: 50px;
  opacity: 0.9;
  color: #1790cc;
  font-family: Omnes;
  font-size: 50px;
  font-weight: 100;
  line-height: 50px;
}
h2 {
  height: 40px;
  opacity: 0.9;
  color: #1790cc;
  font-family: Omnes;
  font-size: 40px;
  font-weight: 300;
  line-height: 40px;
}

h3 {
  height: 30px;
  opacity: 0.9;
  color: #231f20;
  font-family: Omnes;
  font-size: 30px;
  font-weight: 300;
  line-height: 30px;
}
h4 {
  height: 27px;
  opacity: 0.9;
  color: #5e5e5e;
  font-family: Omnes;
  font-size: 25px;
  font-weight: 500;
  line-height: 27px;
}
h5 {
  height: 27px;
  opacity: 0.9;
  color: #5e5e5e;
  font-family: Omnes;
  font-size: 22px;
  font-weight: 300;
  line-height: 27px;
}
h6 {
  height: 16px;
  opacity: 0.9;
  color: #231f20;
  font-family: Omnes;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}
