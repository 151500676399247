.stats-cards-wrapper {
  width: 100%;
  background: #f2f5f7;
  display: flex;
  justify-content: center;
  padding: 0 0 12px;
}

.stats-cards {
  width: 1366px;
}

.stat-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: -1px;
  background: right 50% / 1px 41px no-repeat linear-gradient(#979797, #979797);
  height: 120px;
  width: 244px;
  border-radius: 6px;
  font-weight: 100;
  &:last-child {
    background: none;
  }

  a {
    text-decoration: none;
    font-weight: 100;
  }
  h3,
  p {
    margin: 0;
    padding: 0;
    text-align: center;
  }
  h3 {
    font-size: 22px;
    color: #5e5e5e;
    font-weight: 300;
  }
  p {
    color: #1790cc;
    font-size: 50px;
  }
  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
}
